.content {
    height: 35vh;
    background-color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        padding-top: 5vh;
        padding-bottom: 10vh;
        margin-right: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .TM {
            .T, .M {
                position: absolute;
                width: 10vh;
                transition: 600ms ease;
            }

        }
        
        .TM:hover {
            .T {
                transform: translate(-4.5%, -6%);
            }
        
            .M {
                transform: translate(4.5%, 6%);
            }
        }
    }

    .heart {
	height: 2vh;
    }
    
    h1 {
        color: #bfcee5;
        font-family: 'Eastman-Bold';
        padding-top: 2vh;
        font-size: 2.4vh;
    }

    .social {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            margin-top: 3vh;
            margin-right: 1vh;
            margin-left: 1vh;
            width: 5vh;
            path {
                color: white;
            }
        }
    }
}

.introduction {
    height: 100vh;
    background-color: #333333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
        height: 18vh;
        width: 46vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;

        .line {
            overflow: hidden;
            display: flex;
            flex-wrap: nowrap;
            padding-top: 1vh;
            justify-content: space-between;

            .word {
                display: flex;
                flex-wrap: nowrap;
                padding-right: 1vh;
            }

            h1, h2 {
                height: 1vh;
                font-size: 5vh;
                font-family: 'Eastman-ExtraBold';
                transition: 400ms ease;
                cursor: pointer;
            }

            h1, h2:hover {
                color: #bfcee5;
            }

            h2, h1:hover {
                color: #62b6cb;
            }

            h1:hover, h2:hover {
                transform: translateY(-0.7vh);
                font-size: 6vh;
            }
        }

        .line::after{
            content: "";
            position: relative;
            left: 0;
            height: 10vh;
          }
    }

    .scroll {
        position: absolute;
        left: 50%;
        width: 10vh;
        height: 11vh;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        bottom: 0;
        transform: translateX(-50%);

        &.active {
            background-color: black;
        }

        label {
            pointer-events: none;
            font-family: 'Navine';
            font-size: 2vh;
            color: #bfcee5;
        }
        a {
            text-decoration: none;
        }
        
        span {
            position: absolute;
            display: inline-block;
            top: 2vh;
            height: 2.1vh;
            width: 0.5vh;
            left: calc(50% - 0.1vh);
            animation: animateArrows 1.25s infinite ease;
        }

        span:first-child {
            top: 4vh;
            animation-delay: 0s;
        }
    
        span:nth-child(2) {
            top: 4vh;
            animation-delay: .33s;
        }
    
        span:last-child {
            top: 6vh;
            animation-delay: .66s;
        }
    
        span::before,
        span::after {
            position: absolute;
            content: "";
            width: 0.2vh;
            height: 100%;
            top: 1vh;
            left: 0;
            background: #bfcee5;
            border-radius: 1vh;
        }
    
        span::before {
            transform-origin: bottom;
            transform: rotate(-45deg);
        }
    
        span::after {
            transform-origin: bottom;
            margin-left: -0.1vh;
            transform: rotate(45deg);
        }
    }

    @keyframes  animateArrows {
        0%, 40%, 100% {
            opacity: 0;
        }
        75% {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 768px) {
    .introduction {
        .text {
            height: 14.4vh;
            width: 36.8vh;
            .line{
                .word {
                    h1, h2 {
                        height: 0.8vh;
                        font-size: 4vh;
                    }
                    h1:hover, h2:hover {
                        transform: translateY(-0.7vh);
                        font-size: 4.8vh;
                    }
                }
            }
        }
    }
}
html {
    scroll-behavior: smooth;
}

.navbar {
    position: fixed;
    bottom: 4vh;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 1;

    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: min-content;
        font-size: 2.5vh;
        font-family: 'Navine';
        text-decoration: none;
        color: rgb(233, 233, 233);
        transition: 300ms ease-out;
        padding: 1vh 2vh 1vh 6vh;
        height: 3vh;
    }

    a:hover {
        background-color: #62b6cb;
        font-size: 3.2vh;
        border: 0.1vh solid rgb(233, 233, 233);
    }

    .icon {
        display: none;
        background-color: transparent;
        color: transparent;
        border: none;
        cursor: pointer;
        margin-left: 2vh;
        margin-top: 2vh;
        width: 2vh;
    }

    @media screen and (max-width: 768px) {
        a {
            text-shadow: 0.1vh 0.1vh #3f3f3f;
        }
        .topnav.responsive {
            position: relative;
        }

        .icon {
            display: block;
        }
    }
}

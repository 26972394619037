.content {
    height: auto;
    background-color: #f0bcc4;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        width: 100%;
        color: white;
        font-family: 'GilroyL';
        text-align: center;
        padding-top: 3vh;
        font-size: 5vh;
    }

    h2 {
        width: 100%;
        color: white;
        font-family: 'GilroyL';
        text-align: center;
        padding-bottom: 5vh;
        font-size: 4vh;
    }

    p {
        margin: 0;
    }

    .projects {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 60%;
        margin-top: 6vh;
        margin-bottom: 10vh;
    }
}
.project {
    background-color: rgb(233 , 233, 233);
    width: 35vh;
    height: 25vh;
    margin-bottom: 5vh;
    margin-right: 1vh;
    margin-left: 1vh;
    border-radius: 2vh;
    transition: 500ms ease;
    box-shadow: 0.4vh 0.8vh 1vh #3d3d3d;
    * {
        text-decoration: none;
    }
    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        .githubgimg_project {
            position: absolute;
            flex: 0 0 100%;
            width: 10vh;
            opacity: 0;
        }

        h1, h2, h3, h4, p {
            color: black;
            font-family: 'GilroyL';
            margin: 0;
            padding: 0 !important;
            text-align: center;
        }
    
        .header_project, .description_project {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30vh;
            border-bottom: 0.3vh solid #62b6cb;
        }
    
        .header_project {
            img {
                width: 3vh;
            }
            h1 {
                margin-top: 1vh;
                margin-bottom: 1vh;
                font-size: 2.5vh;
            }
            h4 {
                font-size: 1.2vh;
            }
            h2{
                font-size: 1.2vh;
            }
    
        }
    
        .description_project {
            flex-direction: column;
            height: 16vh;
            p {
                font-size: 1.8vh;
            }
        }
    
        .footer_project {
            width: 30vh;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin: 1vh;
            h3 {
                font-size: 1.7vh;
            }
    
            .star_project, .fork_project {
                display: flex;
                width: 2vh;
                img {
                    width: 2vh;
                    margin-right: 0.4vh;
                }
            }
    
            .star_project {
    
            }
    
            .fork_project {
    
            }
        }
    }
}

.project:hover {
    background-color: rgb(107, 107, 107);
    a>.githubgimg_project {
        opacity: 1;
    }

}

.header {
    .logo {
        .T, .M {
            width: 10vh;
            position: fixed;
            left: 3vh;
            top: 2vh;
            transition: 600ms ease;
        }
    }
    
}

.logo:hover {
    .T {
        transform: translate(-4.5%, -6%);
    }

    .M {
        transform: translate(4.5%, 6%);
    }
}
.content {
    height: 60vh;
    background-color: #606480;
    display: flex;
    align-items: center;
    justify-content: center;

    .pic {
        width: 30vh;
    }
    
    a, a:visited {
        color: #bfcee5;
    }

    p {
        margin: 0;
        width: 50vh;
        font-family: 'GilroyL';
        font-size: 2.5vh;
        color: #bfcee5;
        text-align: justify;
        margin-right: 1vh;
        margin-left: 1vh;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        flex-direction: column;

        .pic {
            width: 20vh;
        }
        p{
            width: 40vh;
            font-size: 2vh;
        }
    }       
}

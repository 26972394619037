.tech {
    width: auto;
    padding: 2vh 1vh 1vh 1vh;
    margin-right: 1vh;
    margin-left: 1vh;

    .logo {
        width: 8vh;
        height: 6vh;
        padding: 0.8vh 1.5vh 0.8vh 1.5vh;
        background-color: rgb(233, 233, 233);
        border-radius: 1vh;
        box-shadow: 0.4vh 0.8vh 1vh #3d3d3d;
    }
}
